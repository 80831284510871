<template >
    <b-container class="py-5">
        <section v-for="(activity, i) in activities" :key="i" class="activity-section">
            <b-row>
                <b-col
                    cols="12"
                    lg="6"
                    class="order-0"
                    :class="i % 2 == 0 ? 'order-lg-0' : 'order-lg-1'"
                >
                    <div
                        class="activity-images-container mx-auto"
                        style="max-width: 500px"
                        data-aos="fade-up"
                        data-aos-duration="3000"
                    >
                        <div class="image-1">
                            <div class="activity-image-card-border rounded">
                                <div class="activity-image-card rounded"></div>
                            </div>
                        </div>
                        <div class="image-2">
                            <div class="activity-image-card-border rounded">
                                <div class="activity-image-card rounded">
                                    <img
                                        :src="
                                            $store.getters['app/domainHost'] +
                                            '/' +
                                            activity.imagePath
                                        "
                                        class="w-100 rounded"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col
                    cols="12"
                    lg="6"
                    class="order-1"
                    :class="i % 2 == 0 ? 'order-lg-1' : 'order-lg-0'"
                >
                    <div
                        class="activity-description-container w-100 rounded mx-auto"
                        style="max-width: 500px"
                    >
                        <h3 data-aos="fade-up" data-aos-duration="3000">{{ activity.title }}</h3>
                        <div
                            data-aos="fade-up"
                            data-aos-duration="2500"
                            class="d-flex align-items-center"
                        >
                            <unicon name="calendar-alt" height="18" fill="#fff" />
                            <span>
                                {{
                                    new Date(activity.date).toLocaleDateString(
                                        "en-UK"
                                    )
                                }}
                            </span>
                        </div>

                        <p
                            class="m-0"
                            data-aos="fade-up"
                            data-aos-duration="2500"
                        >{{ activity.description | truncate(180) }}</p>
                        <b-button
                            data-aos="fade-up"
                            data-aos-duration="2000"
                            :to="'/activities/' + activity.id"
                            type="button"
                            variant="flat-primary"
                            class="text-custom-blue-sky"
                        >
                            {{ t("readMore") }}
                            <unicon name="angle-left" class="arrow" fill="#1AC9FB"></unicon>
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </section>
    </b-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { handleLangChange } from "@/libs/event-bus";
export default {
    computed: {
        ...mapState({
            activities: state => state.webActivities.activities
        })
    },
    setup() {
        const { t } = useI18nUtils();
        return { t };
    },
    metaInfo() {
        return {
            titleTemplate: "Awija - " + this.$t("website.activities"),
            htmlAttrs: {
                lang: this.$i18n.locale
            },
            meta: [
                {
                    name: "description",
                    content: "description"
                },
                {
                    name: "keywords",
                    content: "keywords, keywords, keywords, ..."
                }
            ]
        };
    },
    created() {
        this.fetchWebsiteActivities();
        handleLangChange(this.fetchWebsiteActivities);
    },
    methods: {
        ...mapActions(["fetchWebsiteActivities"])
    }
};
</script>

<style scoped lang="scss">
.activity-section {
    padding: 50px 0;
    color: #fff;
    .activity-images-container {
        height: 400px;
        position: relative;
        .image-1,
        .image-2 {
            perspective: 500px;
            transform-style: preserve-3d;
            position: absolute;
            transform: translateY(-100px);
            height: 100%;
            width: 100%;
            .activity-image-card-border {
                transform-origin: right center;
                transform: scale(0.9) rotate3d(22, 6, 0, -10deg)
                    skew(-8deg, 0deg);
                height: 100%;
                width: 100%;
                padding-top: 3px;
                padding-right: 2px;
                overflow: hidden;
                .activity-image-card {
                    height: 100%;
                    width: 100%;
                }
            }
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                transform: scale(1.2) rotate3d(8, 6, 0, -28deg) skew(3deg, 0deg);
            }
        }
        .image-1 {
            top: 60px;
            .activity-image-card-border {
                border: solid 1px #fff;
            }
        }
        .image-2 {
            top: 54px;
            right: 6px;
        }
    }
    .activity-description-container {
        h3 {
            color: #fff;
            margin: 0;
        }
        span {
            display: block;
            padding: 1rem 0;
        }
        p {
            padding: 0 0 2em;
            text-align: justify;
        }
    }
    &:nth-of-type(2n - 1) {
        .activity-images-container {
            .image-1,
            .image-2 {
                .activity-image-card-border {
                    position: relative;
                    transform-origin: left center;
                    transform: scale(0.9) rotate3d(-22, 6, 0, 10deg)
                        skew(8deg, 0deg);
                }
                img {
                    transform: scale(1.2) rotate3d(8, -6, 0, 28deg)
                        skew(-3deg, 0deg);
                }
            }
            .image-2 {
                right: auto;
                left: 6px;
            }
        }
    }
    @media screen and (max-width: 992px) {
        .activity-images-container {
            .image-1,
            .image-2 {
                .activity-image-card-border {
                    transform: rotate(0deg) !important;
                    img {
                        transform: rotate(0deg) !important;
                    }
                }
            }
        }
    }
}

[dir="ltr"] {
    .activity-section {
        .activity-images-container {
            .image-1,
            .image-2 {
                .activity-image-card-border {
                    transform-origin: right center;
                    transform: scale(0.9) rotate3d(-22, 6, 0, -10deg)
                        skew(-8deg, 0deg);
                }
                img {
                    transform: scale(1.2) rotate3d(8, 6, 0, -28deg)
                        skew(3deg, 0deg);
                }
            }
            .image-2 {
                left: -6px;
                right: auto;
            }
        }
        &:nth-of-type(2n - 1) {
            .activity-images-container {
                .image-1,
                .image-2 {
                    .activity-image-card-border {
                        transform-origin: left center;
                        transform: scale(0.9) rotate3d(-22, 6, 0, -10deg)
                            skew(8deg, 0deg);
                    }
                    img {
                        transform: scale(1.2) rotate3d(8, -6, 0, 28deg)
                            skew(-3deg, 0deg);
                    }
                }
                .image-2 {
                    right: -6px;
                    left: auto;
                }
            }
        }
        .arrow {
            transform: scaleX(-1);
        }
    }
}
</style>
